<template>
  <Section class="section--border-bottom bg-gray-lightestest">
    <Container>
      <Timeline
        :index="step"
        :steps-data="[
          'Modalités',
          'Entités',
          'Récapitulatif',
        ]"
      />
    </Container>
  </Section>
  <Section>
    <Container>
      <p class="h3">Liste des modalités choisies</p>

      <Section>
        <ModalitiesTable
          :onlyItems="$store.state.protocol.modalities"
          :protocol_id="$route.params.id"
        />
      </Section>

      <p class="h3">Liste des entités pour le partage</p>
      <Section>
        <div v-for="(entity, index) in entities" :key="index">
          <p>{{ entity.nom }}</p>
          <hr>
        </div>
      </Section>
    </Container>
  </Section>

</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import ModalitiesTable from '@/views/componentsViews/modalities/ModalitiesTable.vue'

export default {
  name: 'SummaryAddSubview',

  components: {
    ModalitiesTable,
    Section,
    Container,
    Timeline,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      protocol: {
        bpe: false,
      },
      modalities: [],
      entities: [],
      step: 2,
      routeName: this.$route.name,
      previousRouteName: null,
      errors: {},

      // Table
      headers: [],
      staticHeaders: [
        'Désignation',
        'Numéro local',
        'Destruction de la modalité',
        'Type',
        'Partage de la modalité',
      ],
      protocolId: this.$route.params.id,
      factors: [],
      factorIndex: 0,
      passagesFactors: [],
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    if (!this.previousRouteName) {
      this.previousRouteName = 'protocolEntities'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: 'protocolEntities',
      routeParams,
    })
    this.getModalities()
    this.getEntities()
    this.emitter.emit('close-loader')
  },

  methods: {
    getModalities() {
      console.log('modalities?', this.$store.state.protocol.modalities)
      this.$store.state.protocol.modalities.forEach((modality) => {
        this.fetchService.get(`protocole/${this.$route.params.id}/modalite/${modality}`)
          .then((response) => {
            this.modalities.push(response.data)
          })
      })
    },

    getEntities() {
      this.$store.state.protocol.entities.forEach((entity) => {
        this.fetchService.get(`entite/${entity.id}`).then((response) => {
          this.entities.push(response.data)
        })
      })
    },
  },
}
</script>

<style scoped>
</style>
